<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='dataItem'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

                <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Dosyalar</h3>

					<p class="text-lg">
                        Bu alanda personel dosyalarını görüntülüyorsunuz. <br>
                        <br>
                        Dosya eklemek için güncelle butonuna tıklayın.
					</p>

				</Sidebar>

                <Button label='Güncelle' icon='pi pi-pencil' class='p-button-success mr-2 p-2 pl-3 pr-3' @click='editPers' />

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>
             
                <div class='col-12'>

                    <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='5' dataKey='id' :rowHover='true' filterDisplay='menu' :loading='loadingIndicator' responsiveLayout='scroll'>

                        <template #empty>
                            Veri bulunamadı.
                        </template>

                        <template #loading>
                            Personel dosyaları yükleniyor. Lütfen bekleyin.
                        </template>

                        <Column field='insertDate' header='Tarih' filterField='insertDate' :showFilterMatchModes='false' style='width:14rem'>
                            <template #body='{data}'>
                                {{ formatDate(data.insertDate) }}
                            </template>
                        </Column>

                        <Column field='fileType' header='Dosya Tipi' filterField='fileType' :showFilterMatchModes='false'>
                        </Column>

                        <Column field='managerUser.name' header='Yükleyen' filterField='fileType' :showFilterMatchModes='false' style='width:20rem'>
                            <template #body='{data}'>
                                {{ data.managerUser.name + " " + data.managerUser.surName}}
                            </template>
                        </Column>

                        <Column field='FilePath' bodyClass='text-center' header='Görüntüle' filterField='insertDate' :showFilterMatchModes='false' style='width:7.5rem'>
                            <template #body='{data}'>
                                <a :href='"http://image.a1catering.com.tr" + data.filePath' target='_blank'> 
                                    <Button icon='pi pi-eye' class='p-button-rounded p-button-primary'/>
                                </a>
                            </template>
                        </Column>

                    </DataTable>


                </div>

            </div>

        </div>

    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import {
    getEducationList,
    getEmployee,
    getForeignLanguageList, getGenderList, getMaritalStatusList,
    getMilitaryStatusList, getProjectEmployee,
} from '../common/commonFunctions';
import ProjectFileService from '../../services/projectFileService';
import moment from 'moment';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _projectFileService : null,
    created() {
        this._projectFileService = new ProjectFileService();
        this._employeeService = new EmployeeService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
    },
    async mounted() {
        this.dataItem = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        await this.fillDropDownLists()
        await this.getProjectFileList();
    },
    data() {
        return {
            employeeId: 0,
            projectEmployeeId:0,
            dataList:[],
            loadingIndicator:false,
            dataItem: {
                id: '',
                name: '',
                surName: '',
                isActive: '',
                insertDate: '',
                identityNumber: '',
                birthday: '',
                gender: '',
                education: '',
                lastSchool: '',
                militaryStatus: '',
                maritalStatus: '',
                foreignLanguage: '',
                childCount: '',
                address: '',
                cityId: '',
                townId: '',
                gsm: '',
                email: '',
                emergencyPersonName: '',
                emergencyPersonPhone: '',
                employeePhoto: '',
            },
            educationList:[],
            foreignLanguages: [],
            maritalStatuses: [],
            militaryStatuses: [],
            genders: [],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        editPers () {
            this.$router.push({ name: 'editEmployeeFiles', params: { projectEmployeeId: this.projectEmployeeId, employeeId: this.employeeId } });
        },
        async getProjectFileList() {
            let fileResponse = await this._projectFileService.getAllProjectFiles(this.employeeId, "Employee");
            if (fileResponse.isSuccess) {
                this.dataList = fileResponse.data;

            }
        },
        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._employeeService.updateEmployeePersonelInformation(this.employeeId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Çalışan güncelleme başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan güncellemede sorun ile karşılaşıldı');
                }
            } else {
                showErrorMessage(this, 'Çalışan güncellemede sorun ile karşılaşıldı');
            }

        },
        validateForm() {

            return true;
        },
        async fillDropDownLists() {
            this.educationList = await getEducationList();
            this.foreignLanguages = await getForeignLanguageList();
            this.militaryStatuses = await getMilitaryStatusList();
            this.maritalStatuses = await getMaritalStatusList();
            this.genders = await getGenderList();
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
    },
};

</script>


<style scoped>

</style>
